import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//import './js/tagging/mpartic'
import './js/tagging/facebookPixel'
import './js/tagging/twitterPixel'
import './js/tagging/googleTag'

// Vue plugin
import VueSimpleMarkdown from 'vue-simple-markdown'
import vuetify from '@/js/utils/vuetify' // path to vuetify export

// Vue config
Vue.config.productionTip = false
Vue.use(VueSimpleMarkdown)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


// document.addEventListener("keydown", (evt)=>{
//   if (evt.key == "@") {
//     document.querySelector(".debug-collumns").classList.toggle("active")
//   }
// })

// Viewheight Hack
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

if (window.visualViewport) {
  window.visualViewport.addEventListener('resize', (evt)=>{
    let vh = evt.target.height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
} else {
  window.addEventListener('resize', (evt)=>{
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

// Polyfills
window.smoothstep = function smoothstep(min, max, value) {
  var x = Math.max(0, Math.min(1, (value - min) / (max - min)))
  return x * x * (3 - 2 * x)
}

// str byteToHex(uint8 byte)
//   converts a single byte to a hex string 
window.byteToHex = function byteToHex(byte) {
  return ('0' + byte.toString(16)).slice(-2);
}

// str generateId(int len);
//   len - must be an even number (default: 40)
window.generateId = function generateId(len = 40) {
  var arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, byteToHex).join("");
}
