import axios from 'axios'
import jsonCities from '@/assets/colombia-cities-dep.json';

export default {
    name: 'correspondent-search',
    props:{
        autocomplete_label: String,
        autocomplete_error: String,
        inputkey_placeholder: String,
        inputkey_label: String,
        button_search: String,
        list_title: String,
    },

    data: () => ({
        colombiaCities: jsonCities.data,
        paginationLimit: 60,
        valueAutocomplete:'',
        localSearch: null,
        inputDisabled: true,
        valueWordKey: null,
        correspondentArray: null,
        dummyList: true,
        alertStateText: String,
    }),
    beforeMount(){
        if(this.$store.state.page["correspondent"].data.attributes.states_alerts){
            this.alertStateInitial = this.$store.state.page["correspondent"].data.attributes.states_alerts.initial
            this.alertStateHelp = this.$store.state.page["correspondent"].data.attributes.states_alerts.help
            this.alertStateEmpty = this.$store.state.page["correspondent"].data.attributes.states_alerts.empty

            this.alertStateText = this.alertStateInitial
        }
    },
    mounted(){
        this.$el.addEventListener("submit", (evt)=>{
            evt.preventDefault()
            this.onSubmit()
        })
    },

    methods:{

        onSubmit(){
            this.getData()

            setTimeout(() => {
                this.alertStates()
            }, 1200);

        },

        getItemText(item) {
            return `${item.city},  ${item.department}`;
        },

        getData(){
            axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/correspondent-places?filters[city][$containsi]=${this.valueAutocomplete}&filters[address][$containsi]=${this.valueWordKey}&pagination[pageSize]=${this.paginationLimit}`)
            .then((response) => {
                this.correspondentArray = JSON.parse(JSON.stringify(response.data.data))
            })
            .catch(function (error){
                console.log("error",error)
            })
            .then(() =>{
                this.dummyList = false
            })
        },

        alertStates(){
            if(this.correspondentArray && this.correspondentArray.length == 0){
                this.alertStateText = this.alertStateEmpty
                this.dummyList = true
            }else if(this.correspondentArray && this.correspondentArray.length > 0){
                 this.alertStateText = this.alertStateHelp
            }
        },
    },

    computed: {
        items() {
            if(this.correspondentArray){
                return this.descriptionLimit ? this.correspondentArray.slice(0,this.descriptionLimit) : this.correspondentArray
                }
            },
        },

    watch: {
        localSearch(){
            if(this.valueAutocomplete){
                this.inputDisabled = false
            }else{
                this.inputDisabled = true
            }
        },
    },
}